<template>
  <section class="index-start-investing">
    <div class="index-start-investing__container container">
      <div class="index-start-investing__card">
        <div class="index-start-investing__card-info">
          <h2 class="index-start-investing__title" v-t="'index.start-investing.title'" />
          <p class="index-start-investing__text" v-t="'index.start-investing.text'" />
          <a target="_blank" href="https://app.roobee.io/sign_up" class="index-start-investing__start">{{ $t('index.start-investing.start') }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StartInvesting"
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/start-investing';
</style>